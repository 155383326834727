import React from 'react'

const Cookies = ({ acceptCookies }) => {

    const aceptarCookies = () => {
        acceptCookies();
    }


    return (
        <div className='fixed z-50 w-full bottom-0'>
            {/** Pantallas grandes y ratio */}
            <div className='hidden xl:flex custom-ratio:flex w-full bg-white h-10vh' style={{ borderTopLeftRadius: '8vh', borderTopRightRadius: '8vh', borderColor: '#fc5c28', borderTopWidth: '0.5vh' }}>
                <div className='flex justify-evenly items-center text-black w-full p-2vh m-2vh'>
                    <h2 className='text-2.4vh font-semibold text-center'>
                        Utilizamos cookies de Terceros y son necesarias aceptarlas para poder rellenar el formulario.
                    </h2>
                    <button className='custom-button font-semibold text-2.2vh border-orange-700 w-15% text-white' style={{ borderWidth: '0.3vh', padding: '1.2vh', borderRadius: '2vh' }} onClick={aceptarCookies}>
                        Aceptar Cookies
                    </button>
                </div>

            </div>

            {/** Movil */}
            <div className='flex sm:hidden custom-ratio:hidden bg-white h-12vh' style={{ borderTopLeftRadius: '2vh', borderTopRightRadius: '2vh', borderColor: '#fc5c28', borderTopWidth: '0.5vh' }}>
                <div className='flex justify-around items-center text-black p-2vh' style={{gap:'1.2vh'}}>
                    <h2 className='text-1.8vh tracking-tighter font-semibold text-center'>
                        Utilizamos cookies de Terceros y son necesarias aceptarlas para poder rellenar el formulario.
                    </h2>
                    <button className='custom-button font-semibold text-1.8vh border-orange-700 w-60% text-white' style={{ borderWidth: '0.3vh', padding: '0.8vh', borderRadius: '2vh' }} onClick={aceptarCookies}>
                        Aceptar Cookies
                    </button>
                </div>

            </div>

            {/** Medio */}
            <div className='hidden sm:flex xl:hidden custom-ratio:hidden bg-white h-9vh' style={{ borderTopLeftRadius: '2vh', borderTopRightRadius: '2vh', borderColor: '#fc5c28', borderTopWidth: '0.5vh' }}>
                <div className='flex justify-around items-center text-black p-2vh' style={{gap:'1.6vh'}}>
                    <h2 className='text-1.8vh tracking-tighter font-semibold text-center'>
                        Utilizamos cookies de Terceros y son necesarias aceptarlas para poder rellenar el formulario.
                    </h2>
                    <button className='custom-button font-semibold text-1.8vh border-orange-700 w-40% text-white' style={{ borderWidth: '0.3vh', padding: '0.8vh', borderRadius: '2vh' }} onClick={aceptarCookies}>
                        Aceptar Cookies
                    </button>
                </div>

            </div>
        </div>

    )
}

export default Cookies