import image from "../assets/Logo_OLOS.png";

const Tittle = () => {
  return (
    <>
      {/**Pantallas grandes y ratio */}
      <div className="hidden custom-ratio:flex xl:flex flex-col" style={{ gap: '3vh' }}>
        <div className="w-25% h-auto" style={{ marginLeft: '7.5vh', marginTop: '2vh' }}>
          <a href="https://www.olos.com.br/home-es/" target="_blank" rel="noopener noreferrer"><img src={image} alt="Logo de Olos" /></a>
        </div>
        <div className="flex justify-center">
          <h1 className="text-4vh italic text-center">
            <span className="font-bold">ESPECIALISTAS</span> EN SOLUCIONES <span className="font-bold">INNOVADORAS DE RECOBRO</span> MEDIANTE <span className="font-bold"> AGENTES VIRTUALES</span>
          </h1>

        </div>


      </div>

      {/**Medio*/}
      <div className="hidden sm:flex xl:hidden custom-ratio:hidden flex-col items-center gap-3">
        <div className="w-35% h-auto">
          <a href="https://www.olos.com.br/home-es/" target="_blank" rel="noopener noreferrer"><img src={image} alt="Logo de Olos" /></a>
        </div>
        <div className="flex justify-center p-1.5vh">
          <h1 className="text-2vh italic text-center w-95%">
          <span className="font-bold">ESPECIALISTAS</span> EN SOLUCIONES <span className="font-bold">INNOVADORAS DE RECOBRO</span> MEDIANTE <span className="font-bold"> AGENTES VIRTUALES</span>
          </h1>

        </div>


      </div>

      {/**Movil */}
      <div className="flex items-center sm:hidden custom-ratio:hidden flex-col gap-3">
        <div className="w-65% h-auto">
          <a href="https://www.olos.com.br/home-es/" target="_blank" rel="noopener noreferrer"><img src={image} alt="Logo de Olos" /></a>
        </div>
        <div className="flex justify-center p-1.5vh" style={{paddingTop: '0.5vh'}}>
          <h1 className="text-3.6vw italic text-center">
          <span className="font-bold">ESPECIALISTAS</span> EN SOLUCIONES <span className="font-bold">INNOVADORAS DE RECOBRO</span> MEDIANTE <span className="font-bold"> AGENTES VIRTUALES</span>
          </h1>

        </div>


      </div>
    </>

  )
}

export default Tittle