import React, { useState } from 'react'
import Video from './Video';


const Content = ({ openPopUp }) => {
    const [abiertoPop, setAbiertoPop] = useState(false);


    const popUpAbierto = () => {
        setAbiertoPop(true);
    }

    const botonFormulario = () => {
        popUpAbierto();
        openPopUp();     
    }
    



    return (
        <>
            {/**Pantalla grande y ratio */}
            <div className='hidden custom-ratio:flex xl:flex items-center' style={{ gap: '2.8vh', paddingTop: '6vh', paddingLeft: '6vh' }}>
                <div className='w-45%'>
                    <h2 className="text-2.4vh text-center tracking-wide">Solicita tu <span className='font-bold'>estrategia personalizada</span> y comprueba como <span className='font-bold'>te ayudamos</span> desde la perspectiva de <span className='font-bold'>calidad</span> y <span className='font-bold'>bajo coste</span>
                    </h2>
                    <h3 className='text-center text-2.6vh' style={{ marginTop: '4vh', marginBottom: '4vh' }}>
                        Valores Clave:
                    </h3>
                    <div className="flex" style={{ gap: '0.2vh' }}>
                        <div className="flex-1 flex flex-col" style={{ gap: '0.2vh' }}>
                            <div className="flex-1 text-2vh flex items-center justify-center font-bold text-center h-1/2" style={{ padding: '3vh', borderBottomWidth: '0.4vh', borderRightWidth: '0.4vh', borderBottomColor: '#fc5c28', borderRightColor: '#fc5c28', borderRadius: '2vh' }}>Seguimiento sistemático, automatizado y constante</div>
                            <div className="flex-1 text-2vh flex items-center justify-center font-bold text-center h-1/2" style={{ padding: '3vh', borderTopWidth: '0.4vh', borderRightWidth: '0.4vh', borderTopColor: '#fc5c28', borderRightColor: '#fc5c28', borderRadius: '2vh' }}>7x24x365</div>
                        </div>
                        <div className="flex-1 flex flex-col" style={{ gap: '0.2vh' }}>
                            <div className="flex-1 text-2vh flex items-center justify-center text-center h-1/2" style={{ padding: '3vh', borderBottomWidth: '0.4vh', borderLeftWidth: '0.4vh', borderBottomColor: '#fc5c28', borderLeftColor: '#fc5c28', borderRadius: '2vh' }}><h2>Adaptando el <span className='font-bold'> contenido y tono </span>de los <span className='font-bold'>requerimientos </span>durante el <span className='font-bold'>proceso</span></h2></div>
                            <div className="flex-1 text-2vh flex items-center justify-center text-center h-1/2" style={{ padding: '3vh', borderTopWidth: '0.4vh', borderLeftWidth: '0.4vh', borderTopColor: '#fc5c28', borderLeftColor: '#fc5c28', borderRadius: '2vh' }}><h2>Negociación <span className='font-bold'>amistosa con cliente</span></h2></div>

                        </div>
                    </div>
                    <div className='flex justify-center' style={{ margin: '4vh' }}>
                        <button className='custom-button text-2.4vh w-55% rounded-full border-black font-bold ' style={{ padding: '2vh', borderWidth: '0.2vh' }} onClick={botonFormulario} >RESERVAR CITA CON UN CONSULTOR</button>
                    </div>
                </div>


                <div className='w-55%' style={{ paddingRight: '6vh' }}>
                    <Video abiertoPop={abiertoPop}></Video>
                </div>



            </div>

            {/**Medio */}
            <div className='sm:flex hidden custom-ratio:hidden xl:hidden flex-col-reverse items-center' >
                <div className='w-90%'>
                    <h2 className="text-2vh text-center">Solicita tu <span className='font-bold'>estrategia personalizada</span> y comprueba como <span className='font-bold'>te ayudamos</span> desde la perspectiva de <span className='font-bold'>calidad</span> y <span className='font-bold'>bajo coste</span>
                    </h2>
                    <br />
                    <h3 className='text-center text-2.2vh'>
                        Valores Clave:
                    </h3>
                    <br />

                    <div className="flex gap-0.5">
                        <div className="flex-1 gap-0.5 flex flex-col">
                            <div className="flex-1 text-1.7vh flex p-4 tracking-tighter items-center justify-center font-bold border-r-4 border-b-4  text-center rounded-2xl h-1/2" style={{ borderColor: '#fc5c28' }}>Seguimiento sistemático, automatizado y constante</div>
                            <div className="flex-1 text-1.7vh flex p-4 tracking-tighter items-center justify-center font-bold border-r-4 border-t-4 border-orange-500 text-center rounded-2xl h-1/2" style={{ borderColor: '#fc5c28' }}>7x24x365</div>
                        </div>
                        <div className="flex-1 gap-0.5 flex flex-col">
                            <div className="flex-1 text-1.7vh flex p-4 tracking-tighter items-center justify-center border-l-4 border-b-4 border-orange-500 text-center rounded-2xl h-1/2" style={{ borderColor: '#fc5c28' }}><h2>Adaptando el <span className='font-bold'> contenido y tono </span>de los <span className='font-bold'>requerimientos </span>durante el <span className='font-bold'>proceso</span></h2></div>
                            <div className="flex-1 text-1.7vh flex p-4 tracking-tighter items-center justify-center border-l-4 border-t-4 border-orange-500 text-center rounded-2xl h-1/2" style={{ borderColor: '#fc5c28' }}><h2>Negociación <span className='font-bold'>amistosa con cliente</span></h2></div>

                        </div>
                    </div>
                    <div className='flex m-4 justify-center'>
                        <button className='custom-button text-1.8vh font-bold rounded-full p-4 w-45% border-neutral-800 ' onClick={botonFormulario} style={{ borderWidth: '0.5px' }} >RESERVAR CITA CON UN CONSULTOR</button>
                    </div>
                </div>
                <div className='w-90% my-4'>
                    <Video abiertoPop={abiertoPop}></Video>
                </div>
            </div>

            {/**Movil */}
            <div className='flex sm:hidden custom-ratio:hidden flex-col-reverse items-center' >
                <div className='w-90%'>
                    <h2 className="text-3.6vw tracking-tighter text-center">Solicita tu <span className='font-bold'>estrategia personalizada</span> y comprueba como <span className='font-bold'>te ayudamos</span> desde la perspectiva de <span className='font-bold'>calidad</span> y <span className='font-bold'>bajo coste</span>
                    </h2>
        
                    <h3 className='text-center text-3.4vw m-3'>
                        Valores Clave:
                    </h3>
            

                    <div className="flex">
                        <div className="flex-1 flex flex-col">
                            <div className="flex-1 text-2.8vw flex p-2 items-center justify-center border-r-2 border-b-2 font-bold  text-center rounded-md h-1/2" style={{ borderColor: '#fc5c28' }}>Seguimiento sistemático, automatizado y constante</div>
                            <div className="flex-1 text-2.8vw tracking-tighter flex p-2 items-center justify-center font-bold border-r-2 border-t-2 border-orange-500 text-center rounded-md h-1/2" style={{ borderColor: '#fc5c28' }}>7x24x365</div>
                        </div>
                        <div className="flex-1 flex flex-col">
                            <div className="flex-1 text-2.8vw tracking-tighter flex p-2 items-center justify-center border-l-2 border-b-2 border-orange-500 text-center rounded-md h-1/2" style={{ borderColor: '#fc5c28' }}><h2>Adaptando el <span className='font-bold'> contenido y tono </span>de los <span className='font-bold'>requerimientos </span>durante el <span className='font-bold'>proceso</span></h2></div>
                            <div className="flex-1 text-2.8vw flex p-2 items-center justify-center border-l-2 border-t-2 border-orange-500 text-center rounded-md h-1/2" style={{ borderColor: '#fc5c28' }}><h2>Negociación <span className='font-bold'>amistosa con cliente</span></h2></div>
                        </div>
                    </div>
                    <div className='flex m-4 justify-center'>
                        <button className='custom-button text-3.2vw font-bold rounded-full p-2 w-2/3 border-neutral-800 ' onClick={botonFormulario} style={{ borderWidth: '0.5px' }} >RESERVAR CITA CON UN CONSULTOR</button>
                    </div>
                </div>
                <div className='w-90% mx-2 my-3'>
                    <Video abiertoPop={abiertoPop}></Video>
                </div>
            </div>


        </>

    )
}

export default Content