import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import "../styles/style1.css";

const Captcha = ({closeCaptcha, setValueCaptchaFunc}) => {
    
    const [captchaValue, setCaptchaValue] = useState('');

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
        setValueCaptchaFunc(true);
    };

    const comprobacion = () =>{
        if(!captchaValue){
           alert("Confirme el captcha.");
            return;
        }
        closeCaptcha();
    }    

    return (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center'>
            <div className='flex items-center flex-col gap-4'>
            <ReCAPTCHA
                sitekey="6LcQlMYpAAAAADzirnP5YGfCl3PNl1XREe8GrzVM"
                onChange={handleCaptchaChange}
            />
            <button className='custom-button border border-orange-950 rounded-xl w-2/5 p-2 text-center' onClick={comprobacion}>Comprobar</button>
            </div>
            
        </div>
    )
}

export default Captcha