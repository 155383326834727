import React from 'react';

const WhatsappIcon = (props) => (
  <svg
    width={props.width || "24"}
    height={props.height || "24"}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.04 2C6.55 2 2 6.58 2 12.14c0 1.89.49 3.72 1.44 5.34L2 22l4.62-1.43a10.14 10.14 0 0 0 5.42 1.46c5.49 0 9.98-4.58 9.98-10.14C22 6.58 17.53 2 12.04 2zm5.64 14.69c-.24.66-1.4 1.3-1.96 1.34-.5.04-.99.06-1.45-.06a9.7 9.7 0 0 1-4.42-2.38 7.88 7.88 0 0 1-2.29-3.49 9.43 9.43 0 0 1-.36-2.21c.01-.75.15-1.42.42-2.01.22-.47.49-.6.94-.53.15.02.33.01.52.39.21.41.7 1.38.77 1.48.07.1.1.21.02.34-.22.42-.48.81-.73 1.23-.12.22-.24.46-.1.73.72 1.43 1.8 2.52 3.25 3.24.26.13.48.11.65-.08.21-.22.45-.48.68-.73.18-.19.37-.22.59-.14.54.17 1.69.79 1.98.94.29.15.48.22.55.34.08.15.08.84-.16 1.51z"
      fill={props.fill || "#25D366"}
    />
  </svg>
);

export default WhatsappIcon;
