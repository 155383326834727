import { Copyright } from 'lucide-react';

const Footer = () => {
    return (
        <div className='w-full' style={{ padding:'0vh 1vh 1.5vh 1vh', backgroundColor: '#7f2f1433', borderRadius: '2vh'}}>

        {/* Pantallas grandes / medias */}
        <div className='hidden sm:block'>
            <div className='flex flex-row justify-evenly'>


                <div className='w-95% font-bold text-white text-center justify-around flex flex-row' style={{ marginTop: '2vh', marginBottom: '1.5vh', gap:'1vh' }}>

                    <a href="https://www.mirelia.es/politica_privacidad/" style={{ fontSize: '2vh' }} target="_blank" rel="noopener noreferrer">
                        Politica de privacidad
                    </a>
                    <a href="https://www.mirelia.es/politica_cookies/" style={{ fontSize: '2vh' }} target="_blank" rel="noopener noreferrer">
                        Politica de cookies
                    </a>
                    <a href="https://www.mirelia.es/terminos_condiciones/" style={{ fontSize: '2vh' }} target="_blank" rel="noopener noreferrer">
                        Términos y condiciones
                    </a>


                </div>



            </div>
            <div className='flex flex-row text-white justify-center items-center' style={{ fontSize: '2vh' }}>
                <Copyright className=' text-white' style={{ width: '3vh', height: '3vh' }}>

                </Copyright>
                <p style={{ marginLeft: '2vh' }}>2024 Mirelia Digital Talent - Todos los derechos reservados - Web desarrollada por
                    <a className='font-bold' href='https://www.mirelia.es/' target="_blank" rel="noopener noreferrer"> Mirelia</a> </p>

            </div>
        </div>

        {/* Movil */}
        <div className='sm:hidden block'>
            <div className='flex flex-row justify-evenly'>


                <div className='w-full font-bold text-white justify-around flex flex-row text-center' style={{ margin: '4vh 1vh 2vh 1vh' }}>

                    <a href="https://www.mirelia.es/politica_privacidad/" style={{ fontSize: '2vh'}} target="_blank" rel="noopener noreferrer">
                        Politica de privacidad
                    </a>
                    <a href="https://www.mirelia.es/politica_cookies/" style={{ fontSize: '2vh' }} target="_blank" rel="noopener noreferrer">
                        Politica de cookies
                    </a>
                    <a href="https://www.mirelia.es/terminos_condiciones/" style={{ fontSize: '2vh' }} target="_blank" rel="noopener noreferrer"> 
                        Términos y condiciones
                    </a>


                </div>



            </div>
            <div className='flex flex-row text-white justify-center items-center' style={{ fontSize: '2vh' }}>

                <p className='flex' style={{ paddingLeft: '1vh', gap: '0.5vh' }}><Copyright className=' text-white' style={{ width: '3vh', height: '3vh' }} /> <span> 2024 Mirelia Digital Talent - Todos los derechos reservados - Web desarrollada por
                    <a className='font-bold' href='https://www.mirelia.es/' target="_blank" rel="noopener noreferrer"> Mirelia</a></span> </p>

            </div>
        </div>


    </div>


    )
}

export default Footer