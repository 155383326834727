import Tittle from './components/Tittle';
import './App.css';
import Content from './components/Content';
import Footer from './components/Footer';
import PopUp from './components/PopUp';
import { useState } from 'react';
import Cookies from './components/Cookies';


function App() {
  const [isOpenPop, setIsOpenPopUp] = useState(false);
  const [isCookies, setIsCookies] = useState(false);

  const openPopUp = () => {
    setIsOpenPopUp(true);
  }

  const closePopUp = () => {
    setIsOpenPopUp(false);
  }

  const acceptCookies = () => {
    setIsCookies(true);
  }

  return (
    <>
      <div id='background'>

      </div>
      <div>
        {!isCookies && <Cookies acceptCookies={acceptCookies} />}
        {isOpenPop && <PopUp className='z-30' closePopUp={closePopUp} isCookies={isCookies} />}
        <div className='z-20' style={{ marginBottom: '2vh' }}>
          <Tittle />
          <Content openPopUp={openPopUp} />
        </div>
        <div className='z-20'>
          <Footer />
        </div>
      </div>

    </>



  );
}

export default App;
