import React, { useEffect, useRef, useState } from 'react';
import videoOlos from "../videos/videoOlos.mp4";
import { Play, Pause, Rewind } from "lucide-react";
import portada from "../assets/Portada.png";

const Video = ({abiertoPop}) => {
    const [videoStatus, setVideoStatus] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        videoRef.current.pause();
    }
        , [])

    const changeStatus = () => {
        videoRef.current.poster = "";
        if (!videoStatus) {
            videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
        setVideoStatus(!videoStatus);
    }

    const rewindFunc = () => {
        videoRef.current.currentTime -= 10;
    }

    return (
        <div className={`relative text-right ${abiertoPop ? '-z-10' : 'z-10'}`}>
            <video poster={portada} ref={videoRef} onEnded={changeStatus} className="border-orange-700 shadow-orange-400" style={{borderRadius:'3vh', borderWidth: '0.2vh'}}>
                <source src={videoOlos} type="video/mp4">
                </source>
                Your browser does not support the video tag.
            </video>
            <div className='absolute p-1.2vh' style={{ bottom: '0%', right: '0%' }}>
                <div className='flex'>
                    <div>
                        <button className='custom-button flex items-center border-white p-0.4vh w-3.5vh h-3.5vh sm:w-5vh sm:h-5vh custom-ratio:w-6vh custom-ratio:h-6vh xl:w-6vh xl:h-6vh' style={{ borderWidth: '0.2vh', marginRight:'1vh', display: 'flex', justifyContent: 'center', borderRadius:'100%'}} onClick={changeStatus}>{videoStatus ? <Pause className=' sm:w-3vh sm:h-3vh custom-ratio:w-4vh custom-ratio:h-4vh xl:w-4vh xl:h-4vh' /> : <Play className='sm:w-3vh sm:h-3vh custom-ratio:w-4vh custom-ratio:h-4vh xl:w-4vh xl:h-4vh' />}</button>
                    </div>
                    <div>
                        <button className='custom-button flex items-center border-white p-0.4vh w-3.5vh h-3.5vh sm:w-5vh sm:h-5vh custom-ratio:w-6vh custom-ratio:h-6vh xl:w-6vh xl:h-6vh' style={{ borderWidth: '0.2vh', display: 'flex', justifyContent: 'center', borderRadius:'100%'}} onClick={rewindFunc}> <Rewind className='sm:w-3vh sm:h-3vh custom-ratio:w-4vh custom-ratio:h-4vh xl:w-4vh xl:h-4vh' /></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Video