import React, { useState } from 'react';
import "../styles/style1.css";
import image from "../assets/Logo_OLOS.png";
import Captcha from './Captcha';
import WhatsappIcon from './Whatsapp';


const PopUp = ({ closePopUp, isCookies }) => {
    const [nombrePersona, setNombrePersona] = useState('');
    const [apellidoPersona, setApellidoPersona] = useState('');
    const [nombreEmpresa, setNombreEmpresa] = useState('');
    const [prefijo, setPrefijo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [openCaptcha, setOpenCaptcha] = useState('');
    const [valueCaptcha, setValueCaptcha] = useState('');


    const validarNoLetra = (x) => {
        var regex = /[a-zA-Z]/;
        return regex.test(x);
    }

    const validarEmail = (x) => {
        var regex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*(\.[a-zA-Z]{2,})$/;
        return regex.test(x);
    }

    const validarPrefijo = (x) => {
        var regex = /^\+?(?:\d{1,3}(?:-\d{1,3})?|\d{1,2})(?:-\d{1,2})?$/;
        return regex.test(x);
    }

    const validarFormulario = () => {
        if (!isCookies) {
            alert('Por favor, acepte las cookies.');
            return true;
        } else {
            if (!checkbox) {
                alert('Por favor, es necesario aceptar las condiciones de privacidad.');
                return true;
            } else {
                if (!nombrePersona.trim() && !nombreEmpresa.trim() && !telefono.trim() && !email.trim()) {
                    alert('Por favor, rellene los campos del formulario.');
                    return true;
                } else {
                    if (!nombrePersona.trim()) {
                        alert('Por favor, introduzca su nombre.');
                        return true;
                    } else {
                        if (!nombreEmpresa.trim()) {
                            alert('Por favor, introduzca el nombre de la empresa.')
                            return true;
                        } else {
                            if (validarNoLetra(prefijo)) {
                                alert('No puede contener ninguna letra el prefijo.');
                                return true;
                            } else {
                                if (!validarPrefijo(prefijo) && prefijo.trim()) {
                                    alert('Formato del prefijo incorrecto, por favor fíjese en estos ejemplos. \n (+34, +1-783, +351, 34, 351)')
                                    return true;
                                } else {
                                    if (!telefono.trim()) {
                                        alert('Por favor, introduzca el numero de contacto.');
                                        return true;
                                    } else {
                                        if (validarNoLetra(telefono)) {
                                            alert('No puede contener ninguna letra el teléfono.');
                                            return true;
                                        } else {
                                            if (!email.trim()) {
                                                alert('Por favor, introduzca el correo.');
                                                return true;
                                            } else {
                                                if (!validarEmail(email)) {
                                                    alert('Email no válido.');
                                                    return true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

        return false;
    }

    const openCaptchaFunc = () => {
        if (!validarFormulario()) {
            setValueCaptcha(false);
            setOpenCaptcha(true);

        }

    }

    const handleSubmit = () => {
        const producto = 'Way Recobros';
        const nombre = nombrePersona;
        const apellido = apellidoPersona;
        const empresa = nombreEmpresa;

        fetch('../landingrefresh.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                nombre,
                apellido,
                empresa,
                prefijo,
                telefono,
                email,
                producto
            }),
        })

        alert("Enviado correctamente.");
        closePopUp();
    }


    const closeCaptcha = () => {
        setOpenCaptcha(false);
        handleSubmit();
    }

    const setValueCaptchaFunc = (x) => {
        setValueCaptcha(x);
    }



    return (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50'>
            {openCaptcha && <Captcha className="z-50" closeCaptcha={closeCaptcha} setValueCaptchaFunc={setValueCaptchaFunc} />}
            <div className='flex justify-center place-items-center w-full h-full top-0 left-0'>
                {/**Pantallas grandes y ratio */}
                <div className='hidden custom-ratio:flex xl:flex flex-col items-center bg-white w-45%' style={{ borderWidth: '0.5vh', borderRadius: '6vh', borderColor: '#fc5c28' }}>
                    <div className='w-80%' style={{ paddingTop: '3vh' }}>
                        <img src={image} alt="Logo de Olos" style={{ paddingRight: '3vh', paddingLeft: '3vh' }} />
                    </div>
                    <div className='flex flex-col items-center text-center' style={{ padding: '1vh' }}>
                        <h2 className='flex items-center font-bold text-neutral-600 text-2.4vh' style={{ gap: '1vh' }}>Llamanos o envianos un <WhatsappIcon width={"4vh"} height={"4vh"} /> +34 683 13 59 63</h2>
                        <h2 className='font-bold text-neutral-600 text-2.4vh'>o</h2>
                        <h2 className='font-bold text-neutral-600 text-2.4vh'>si lo prefieres te llamamos nosotros</h2>
                    </div>

                    <form className='flex flex-col' style={{ padding: '3vh 3vh 0vh 3vh' }}>
                        <div className='flex' style={{ gap: '3vh' }}>
                            <div className='flex flex-col w-full' style={{ paddingBottom: '1vh' }}>
                                <label className='text-neutral-700 text-2.4vh' htmlFor="nombrePersona">Nombre*</label>
                                <input className="custom-input bg-white text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="nombrePersona" id="nombrePersona" onChange={(e) => setNombrePersona(e.target.value)} />
                            </div>

                            <div className='flex flex-col w-full' style={{ paddingBottom: '1vh' }}>
                                <label className='text-neutral-700 text-2.4vh' htmlFor="apellidoPersona">Apellido</label>
                                <input className="custom-input bg-white text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="apellidoPersona" id="apellidoPersona" onChange={(e) => setApellidoPersona(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex' style={{ gap: '3vh' }}>
                            <div className='flex flex-col w-full' style={{ paddingBottom: '1vh' }}>
                                <label className='text-neutral-700 text-2.4vh' htmlFor="nombreEmpresa">Nombre Empresa*</label>
                                <input className="custom-input bg-white text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="nombreEmpresa" id="nombreEmpresa" onChange={(e) => setNombreEmpresa(e.target.value)} />
                            </div>
                            <div className='flex flex-col w-full' style={{ paddingBottom: '1vh' }}>
                                <label className='text-neutral-700 text-2.4vh' htmlFor="telefono">Telefono*</label>
                                <div className='flex'>
                                    <input className="custom-input bg-white w-3/12 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="tel" name="prefijo" id="prefijo" onChange={(e) => setPrefijo(e.target.value)} placeholder='+34' />
                                    <input className="custom-input bg-white w-9/12 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="tel" name="telefono" id="telefono" onChange={(e) => setTelefono(e.target.value)} />

                                </div>

                            </div>
                        </div>
                        <div className='flex'>
                            <div className='flex flex-col w-full' style={{ paddingBottom: '1vh' }}>
                                <label className='text-neutral-700 text-2.4vh' htmlFor="correo">Email*</label>
                                <input className="custom-input bg-white text-neutral-800 focus:outline-none focus:bg-neutral-100" type="email" name="correo" id="correo" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex justify-items-center items-center' style={{ paddingTop: '2vh', gap: '1.2vh' }}>
                            <input className='bg-neutral-300 w-2.4vh h-2.4vh ' type="checkbox" name="checkPrivacidad" id="checkPrivacidad" onChange={(e) => setCheckbox(e.target.value)} />
                            <label className='text-neutral-700 tracking-tighter text-2.5vh' htmlFor="checkPrivacidad">Acepto la política <a className='text-blue-800' href="https://www.mirelia.es/politica_privacidad/" target="_blank" rel="noopener noreferrer">de privacidad</a> y <a className='text-blue-800' href="https://www.mirelia.es/politica_privacidad/" target="_blank" rel="noopener noreferrer">protección de datos</a> </label>
                        </div>

                        <div className='flex justify-center' style={{ margin: '4vh 0vh' }}>
                            <button className='custom-button text-2.5vh border-orange-700 w-40%' style={{ borderWidth: '0.3vh', padding: '0.8vh', borderRadius: '2vh' }} type='button' onClick={openCaptchaFunc}>Enviar</button>
                        </div>

                    </form>

                </div>

                {/**Medio */}
                <div className='hidden sm:flex custom-ratio:hidden xl:hidden flex-col items-center bg-white border-4 rounded-3xl w-80%' style={{ borderColor: '#fc5c28' }}>
                    <div className='pt-4 w-80%'>
                        <img src={image} alt="Logo de Olos" />
                    </div>
                    <div className='flex flex-col items-center p-2 text-center'>
                        <h2 className='flex items-center font-bold text-neutral-600 text-1.9vh' style={{ gap: '0.8vh' }}>Llamanos o envianos un <WhatsappIcon width={"2.6vh"} height={"2.6vh"} /> +34 683 13 59 63</h2>
                        <h2 className='font-bold text-neutral-600 text-1.9vh'>o</h2>
                        <h2 className='font-bold text-neutral-600 text-1.9vh'>si lo prefieres te llamamos nosotros</h2>
                    </div>
                    <form className='flex flex-col px-6 pt-2 w-90%'>
                        <div className='flex flex-col'>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="nombrePersona">Nombre*</label>
                                <input className="custom-input bg-white rounded-md text-1.4vh text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="nombrePersona" id="nombrePersona" onChange={(e) => setNombrePersona(e.target.value)} />
                            </div>

                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="apellidoPersona">Apellido</label>
                                <input className="custom-input bg-white text-1.4vh rounded-md text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="apellidoPersona" id="apellidoPersona" onChange={(e) => setApellidoPersona(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="nombreEmpresa">Nombre Empresa*</label>
                                <input className="custom-input bg-white text-1.4vh rounded-md py-2 px-2 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="nombreEmpresa" id="nombreEmpresa" onChange={(e) => setNombreEmpresa(e.target.value)} />
                            </div>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="telefono">Telefono*</label>
                                <div className='flex'>
                                    <input className="custom-input bg-white text-1.4vh rounded-md py-2 px-2 w-20% text-neutral-800 focus:outline-none focus:bg-neutral-100" type="tel" name="prefijo" id="prefijo" onChange={(e) => setPrefijo(e.target.value)} placeholder='+34' />
                                    <input className="custom-input bg-white text-1.4vh rounded-md py-2 px-2 w-80% text-neutral-800 focus:outline-none focus:bg-neutral-100" type="tel" name="telefono" id="telefono" onChange={(e) => setTelefono(e.target.value)} />

                                </div>

                            </div>
                        </div>
                        <div className='flex'>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="correo">Email*</label>
                                <input className="custom-input bg-white rounded-md text-1.4vh py-2 px-2 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="email" name="correo" id="correo" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex justify-items-center items-center pt-2 gap-2'>
                            <input className='bg-neutral-300 w-2vh h-2vh' type="checkbox" name="checkPrivacidad" id="checkPrivacidad" onChange={(e) => setCheckbox(e.target.value)} />
                            <label className='text-neutral-700 tracking-tighter text-1.8vh' htmlFor="checkPrivacidad">Acepto la política <a className='text-blue-800' href="https://www.mirelia.es/politica_privacidad/" target="_blank" rel="noopener noreferrer">de privacidad</a> y <a className='text-blue-800' href="https://www.mirelia.es/politica_privacidad/" target="_blank" rel="noopener noreferrer">protección de datos</a></label>
                        </div>

                        <div className='flex justify-center' style={{ marginBottom: '2vh', marginTop: '2vh' }}>
                            <button className='custom-button border border-orange-700 rounded-xl w-40% p-1vh text-2.2vh' type='button' onClick={openCaptchaFunc}>Enviar</button>
                        </div>

                    </form>

                </div>

                {/**Movil */}
                <div className='flex sm:hidden custom-ratio:hidden flex-col items-center bg-white border-2 rounded-3xl w-90%' style={{ borderColor: '#fc5c28' }}>
                    <div className='pt-4 w-80%'>
                        <img src={image} alt="Logo de Olos" />
                    </div>
                    <div className='flex flex-col items-center p-2 text-center'>
                        <h2 className='flex items-center font-bold text-neutral-600 text-1.5vh' style={{ gap: '0.5vh' }}>Llamanos o envianos un <WhatsappIcon width={"2vh"} height={"2vh"} /> +34 683 13 59 63</h2>
                        <h2 className='font-bold tracking-tighter text-neutral-600 text-1.5vh'>o</h2>
                        <h2 className='font-bold tracking-tighter text-neutral-600 text-1.5vh'>si lo prefieres te llamamos nosotros</h2>
                    </div>
                    <form className='flex flex-col px-6 pt-2'>
                        <div className='flex flex-col'>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="nombrePersona">Nombre*</label>
                                <input className="custom-input bg-white rounded-md text-1.4vh text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="nombrePersona" id="nombrePersona" onChange={(e) => setNombrePersona(e.target.value)} />
                            </div>

                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="apellidoPersona">Apellido</label>
                                <input className="custom-input bg-white text-1.4vh rounded-md text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="apellidoPersona" id="apellidoPersona" onChange={(e) => setApellidoPersona(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="nombreEmpresa">Nombre Empresa*</label>
                                <input className="custom-input bg-white text-1.4vh rounded-md py-2 px-2 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="text" name="nombreEmpresa" id="nombreEmpresa" onChange={(e) => setNombreEmpresa(e.target.value)} />
                            </div>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="telefono">Telefono*</label>
                                <div className='flex'>
                                    <input className="custom-input bg-white text-1.4vh rounded-md py-2 px-2 w-3/12 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="tel" name="prefijo" id="prefijo" onChange={(e) => setPrefijo(e.target.value)} placeholder='+34' />
                                    <input className="custom-input bg-white text-1.4vh rounded-md py-2 px-2 w-9/12 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="tel" name="telefono" id="telefono" onChange={(e) => setTelefono(e.target.value)} />

                                </div>

                            </div>
                        </div>
                        <div className='flex'>
                            <div className='flex flex-col pb-2 w-full'>
                                <label className='text-neutral-700 text-2.2vh' htmlFor="correo">Email*</label>
                                <input className="custom-input bg-white rounded-md text-1.4vh py-2 px-2 text-neutral-800 focus:outline-none focus:bg-neutral-100" type="email" name="correo" id="correo" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>
                        <div className='flex justify-items-center items-center pt-2 gap-2'>
                            <input className='bg-neutral-300' type="checkbox" name="checkPrivacidad" id="checkPrivacidad" onChange={(e) => setCheckbox(e.target.value)} />
                            <label className='text-neutral-700 tracking-tighter text-1.8vh' htmlFor="checkPrivacidad">Acepto la política <a className='text-blue-800' href="https://www.mirelia.es/politica_privacidad/" target="_blank" rel="noopener noreferrer">de privacidad</a> y <a className='text-blue-800' href="https://www.mirelia.es/politica_privacidad/" target="_blank" rel="noopener noreferrer">protección de datos</a> </label>
                        </div>

                        <div className='flex justify-center' style={{ marginBottom: '2vh', marginTop: '2vh' }}>
                            <button className='custom-button border border-orange-700 rounded-xl w-50% p-1vh text-2vh' type='button' onClick={openCaptchaFunc}>Enviar</button>
                        </div>

                    </form>

                </div>

            </div>
        </div>
    )
}

export default PopUp